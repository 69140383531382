import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentSwitch.figmaUrl.android} codeUrl={checklists.componentSwitch.codeUrl.android} checklists={checklists.componentSwitch.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Switch is component that allows the user to toggle between two states. Switch is usually used for implementing toggle based settings, where the user can change the setting parameters to either on or off.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/react-native/react-native-switch-1.gif",
            "alt": "switch react native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <p>{`An example on how to implement switch component in your app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Switch } from "@legion-crossplatform/ui";

const Switch = () => {
  return <Switch label="Switch Label" defaultChecked={false} size="$3" />;
};
`}</code></pre>
    <div className="divi" />
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center",
        "width": 250,
        "height": 500
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/android-1.png",
            "alt": "switch preview"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Example With Value Toggle`}</h2>
    <p>{`The following is example usage for switch implementation with value`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Switch } from "@legion-crossplatform/ui";
import { useState } from "react";

const [checked, setChecked] = useState(false);

const toggleCheckedChange = () => {
  setChecked(!checked);
};

const Switch = () => {
  return (
    <Switch
      label="Switch Label"
      defaultChecked={false}
      size="$3"
      value={checked}
      onCheckedChange={toggleCheckedChange}
    />
  );
};
`}</code></pre>
    <div className="divi" />
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center",
        "width": 250,
        "height": 500
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "width": 220,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/switch/switch_preview_2.gif",
            "alt": "switch preview"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`any`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current value of the switch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text label for this switch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCheckedChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(checked: boolean) => void`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback in which value should be updated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`checked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Control for the input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set switch disabled or not`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`labeledBy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set aria-labeled-by`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`required`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set aria-required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      